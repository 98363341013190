
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Image, VueImagePickerPlugin } from './types';
import { VueConstructor } from 'vue';

@Component
export default class PickerUpload extends Vue implements VueImagePickerPlugin {
    public name: string = 'upload';

    public showed: boolean = false;

    @Prop({ default: () => { /* empty */ } })
    private strings: { [key: string]: string };
    private t: { [key: string]: string };

    @Prop()
    private uploadHandler: (file: File, index: number) => Promise<void>;
    // OR
    @Prop()
    private uploadUrl: string;
    @Prop()
    private defaultHeaders?: { [key: string]: string } | (() => { [key: string]: string });
    @Prop()
    private uploadSuccessHandler: (image: Image, index: number) => Promise<void>;
    @Prop()
    private uploadErrorHandler: (file: File, index: number, status: number, response: any) => Promise<void>;

    @Prop({ required: false })
    private buttonCustomClass?: string;

    @Prop({ default: false })
    private multiple: boolean;

    @Prop({ default: 'image/*' })
    private accept: string | null;

    private uploading: boolean = false;
    private highlited: boolean = false;

    private uploadPercent: number = 0;
    private uploadProgress: number[] = [];

    private errorMessage: string | null = null;

    public created() {
        this.t = {
            uploadButton: 'Upload',
            hint: 'or drag and drop files here',
            progress: 'Progress:',
            unhandledError: 'Unknown error',
        };

        this.t = Object.assign(this.t, this.strings);
    }

    public mounted() {
        const dropArea = this.$refs.dropArea as Element;

        ['dragenter', 'dragover'].forEach(eventName => {
            dropArea.addEventListener(eventName, function(this: PickerUpload, event: any) {
                this.highlited = true;
                this.preventDefaults(event);
            }.bind(this), false);
        });

        ['dragleave'].forEach(eventName => {
            dropArea.addEventListener('dragleave', function(this: PickerUpload, event: any) {
                this.highlited = false;
                this.preventDefaults(event);
            }.bind(this), false);
        });

        dropArea.addEventListener('drop', this.handleDrop, false);
    }

    public startUploading(filesCount: number) {
        this.uploading = true;
        this.uploadPercent = 0;
        this.uploadProgress = [];
        for (let i = filesCount; i > 0; i--) {
            this.uploadProgress.push(0);
        }
    }

    private preventDefaults(event: any) {
        event.preventDefault();
        event.stopPropagation();
    }

    private handleDrop(event: any) {
        const dt = event.dataTransfer;
        if (!dt) {
            return;
        }

        let files = [...dt.files];

        if (this.accept) {
            const acceptRegex = new RegExp(this.accept.replace('*', '.*'));
            files = files.filter((f: File) => f.type.match(acceptRegex));
        }

        if (files.length === 0) {
            return;
        }

        this.handleFiles(files);

        this.highlited = false;
        this.preventDefaults(event);
    }

    private handleFiles(files: File[] | FileList) {
        let arr = (files as FileList) ? Array.from(files) : files as File[];

        if (!this.multiple) {
            arr = arr.slice(0, 1);
        }

        this.startUploading(arr.length);
        arr.forEach((file, index) => {
            if (this.uploadHandler) {
                this.uploadHandler(file, index).then(f => {
                    this.uploading = false;
                });
            } else {
                this.defaultUploadHandler(file, index);
            }

        });
    }

    private async defaultUploadHandler(file: File, index: number): Promise<void> {
        const xhr = new XMLHttpRequest();
        const formData = new FormData();
        xhr.open('POST', this.uploadUrl, true);
        if (this.defaultHeaders) {
            const headers = (typeof this.defaultHeaders === 'function') ? this.defaultHeaders() : this.defaultHeaders;
            for (const h in headers) {
                xhr.setRequestHeader(h, headers[h]);
            }
        }

        xhr.upload.addEventListener('progress', function(this: PickerUpload, e: any) {
            this.updateProgress(index, (e.loaded * 100.0 / e.total) || 100);
        }.bind(this));

        xhr.addEventListener('readystatechange', function(this: PickerUpload, e: Event) {
            if (xhr.readyState === 4 && xhr.status === 200) {
                if (typeof this.uploadSuccessHandler === 'function') {
                    const image = (JSON.parse(xhr.response) as Image[])[0];
                    this.uploadSuccessHandler(image, index);
                }
                this.uploading = false;
                (this.$refs.input as HTMLInputElement).value = '';
            } else if (xhr.readyState === 4 && xhr.status !== 200) {
                if (typeof this.uploadErrorHandler === 'function') {
                    this.uploadErrorHandler(file, index, xhr.status, xhr.response)
                        .then(() => {
                            this.errorMessage = null;
                        })
                        .catch((error: Error) => {
                            this.errorMessage = error.message;
                        })
                        .finally(() => {
                            this.uploading = false;
                        });
                } else {
                    this.errorMessage = this.t.unhandledError;
                    this.uploading = false;
                }
            }
        }.bind(this));

        formData.append('files', file);
        xhr.send(formData);
    }

    private updateProgress(fileNumber: number, percent: number) {
        this.uploadProgress[fileNumber] = percent;
        const result = this.uploadProgress.reduce((total: number, curren: number) => total + curren, 0) / this.uploadProgress.length;
        this.uploadPercent = result;
    }

    private clearError() {
        this.errorMessage = null;
    }
}
